import React from "react";
import tw from "twin.macro";
import { capitalize } from "../lib/util";

const isActive = tw`font-bold text-red-500`;

const FilterNav = ({
  selectedFlags,
  selectedGenre,
  uniqueGenres,
  genreCount = {},
  onSelectFlag,
  onSelectGenre,
}) => (
  <nav tw="space-y-8">
    <ul tw="list-none mb-4 space-y-1">
      <li tw="font-semibold text-sm text-gray-500 mb-2">SPECIAL NEEDS</li>

      {Object.entries(selectedFlags).map(([key, value]) => (
        <li
          key={key}
          onClick={() => onSelectFlag(key)}
          css={[tw`cursor-pointer block text-gray-200`, value && isActive]}>
          {value && isActive ? "✓ " + capitalize(key) : capitalize(key)}
        </li>
      ))}
    </ul>

    <ol tw="list-none text-base  space-y-1">
      <li tw="font-semibold text-sm text-gray-500 mb-2">GENRE</li>

      <li
        css={[tw`cursor-pointer block text-gray-400 mb-2`, !selectedGenre && isActive]}
        onClick={() => onSelectGenre("")}>
        All genres
      </li>

      {uniqueGenres.map((genre) => (
        <li
          tw="cursor-pointer text-gray-200 flex justify-between"
          css={genre === selectedGenre && isActive}
          onClick={() => onSelectGenre(genre)}
          key={genre}>
          <span>{genre === selectedGenre && genre != "" ? "✓ " + genre : genre}</span>

          <span tw="text-gray-500">{genreCount[genre]}</span>
        </li>
      ))}
    </ol>
  </nav>
);

export default FilterNav;
