import * as React from "react";
import { motion } from "framer-motion";
import "twin.macro";

const GameItem = ({ entry }) => {
  const playlistUrl =
    entry.playlist.length < 15
      ? "https://www.youtube.com/watch?v=" + entry.playlist
      : "https://www.youtube.com/playlist?list=" + entry.playlist;
  const categories = entry.genre.split(", ");

  const game = entry.game
    .replace(/[~`!@#$%^&*()+={}[\];:'"<>.,/\\?_]/g, "")
    .split(" ")
    .join("-")
    .replace("ö", "o")
    .toLowerCase();

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <motion.article
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}>
      <a tw="relative w-full aspect-video block" href={playlistUrl}>
        <ul tw="list-none space-x-2 z-50 relative p-2 inline-block">
          {entry.f ? (
            <>
              <li tw="bg-black text-white px-2 py-1 text-xs font-bold inline-block rounded">
                Facecam
              </li>
            </>
          ) : (
            ""
          )}
          {entry.j ? (
            <>
              <li tw="bg-red-600 text-white px-2 py-1 text-xs font-bold inline-block rounded">
                Odd scared
              </li>
            </>
          ) : (
            ""
          )}
          {entry.s ? (
            <>
              <li tw="bg-black text-white px-2 py-1 text-xs font-bold inline-block rounded">
                Stream
              </li>
            </>
          ) : (
            ""
          )}
          {entry.status === "Unfinished" ? (
            <>
              <li tw="bg-yellow-600 text-white px-2 py-1 text-xs font-bold inline-block rounded">
                Incomplete
              </li>
            </>
          ) : (
            ""
          )}
          {entry.ea === "X" ? (
            <>
              <li tw="bg-yellow-600 text-white px-2 py-1 text-xs font-bold inline-block rounded">
                Early Access
              </li>
            </>
          ) : (
            ""
          )}
          {entry.mp != "" ? (
            <>
              <li tw="bg-blue-600 text-white px-2 py-1 text-xs font-bold inline-block rounded">
                Multiplayer
              </li>
            </>
          ) : (
            ""
          )}
        </ul>

        <figure tw="w-full h-full absolute inset-0">
          <img
            tw="h-full w-full transition-all ease-in-out duration-300 md:opacity-90 md:grayscale-[50%] rounded-md md:hover:grayscale-0 md:hover:scale-105 md:hover:opacity-100 md:hover:shadow-[0px_40px_80px_-10px_rgba(239,68,68,0.2)]"
            title={entry.game}
            src={`../../playlists/${game}${entry.ea != "" ? "-early-access" : ""}${
              entry.mp != "" ? "-mp" : ""
            }.jpg`}
            loading="lazy"
          />
        </figure>
      </a>
      <div tw="">
        <h2 tw="text-lg font-bold mt-4">{entry.game}</h2>
        <ul tw="list-none">
          {categories.map((category) => (
            <li tw="text-sm inline text-gray-400 mr-2" key={category}>
              {category}
            </li>
          ))}
        </ul>
      </div>
      <p tw="mt-2 hidden">Status: {entry.status != 0 ? entry.status : "Unknown"}</p>
      <p></p>
    </motion.article>
  );
};

export default GameItem;
