import { graphql } from "gatsby";
import * as React from "react";
import { useMemo, useState } from "react";
import "twin.macro";
import FilterNav from "../components/filter-nav";
import GameItem from "../components/game-item";
import Layout from "../components/layout";
import SearchInput from "../components/SearchInput";
import { SEO } from "../components/seo";
import { playlists } from "../lib/stats";

// Genre Array
const genreSet = new Set(playlists.map((game) => game.genre.split(", ")).flat());
const uniqueGenres = [...genreSet].sort();

const genreCount = playlists.reduce((acc, game) => {
  game.genre.split(", ").forEach((genre) => {
    acc[genre] = (acc[genre] ?? 0) + 1;
  });

  return acc;
}, {});

// Filter
const filterByGenre = (data, genre) => {
  if (!genre) return data;

  const filteredGenres = data.filter((game) => game.genre.indexOf(genre) !== -1);
  return filteredGenres;
};

const filterBySearch = (data, search) => {
  if (!search) return data;

  const filteredGenres = data.filter((game) =>
    game.game.toLowerCase().includes(search.toLowerCase()),
  );
  return filteredGenres;
};

const IndexPage = () => {
  const [search, setSearch] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedFlags, setSelectedFlags] = useState({
    jumpscare: false,
    facecam: false,
    stream: false,
    multiplayer: false,
  });

  const filteredList = useMemo(() => {
    const filterList = [
      selectedFlags.jumpscare && "j",
      selectedFlags.facecam && "f",
      selectedFlags.stream && "s",
      selectedFlags.multiplayer && "mp",
    ].filter((t) => t);

    // Check if playlist genre set is a subset of selected genre set
    return filterByGenre(filterBySearch(playlists, search), selectedGenre).filter(
      (playlist) => {
        if (!Object.values(selectedFlags).some((t) => t)) return true;

        const playlistSet = new Set(
          [playlist.j && "j", playlist.s && "s", playlist.f && "f", playlist.mp && "mp"].filter(
            (t) => t,
          ),
        );

        return filterList.every((i) => playlistSet.has(i));
      },
      [selectedGenre],
    );
  }, [search, selectedFlags, selectedGenre]);

  const searchEl = <SearchInput onSearch={setSearch} placeholder="Search for titles..." />;

  const countSpecialNeeds = Object.values(selectedFlags).filter((t) => t).length;

  return (
    <Layout tw="md:flex items-start" header={<div tw="hidden md:block">{searchEl}</div>}>
      <div tw="md:hidden mb-8">{searchEl}</div>

      <details
        tw="border-t border-b py-3 border-gray-800 border-solid block md:hidden mb-8"
        css={`
          &[open] {
            padding-bottom: 24px;
          }
          &[open] summary {
            margin-bottom: 16px;
          }
          &[open] summary:after {
            content: " -";
            margin-right: 2px;
          }
          summary::-webkit-details-marker {
            display: none;
          }
        `}>
        <summary tw="list-none place-content-between items-center after:content-[' +'] appearance-none text-base after:text-2xl flex outline-none">
          <span>
            Filter
            {countSpecialNeeds > 0 && (
              <span tw="text-gray-500 text-sm"> - {countSpecialNeeds} needs</span>
            )}
            {selectedGenre && <span tw="text-gray-500 text-sm"> - {selectedGenre}</span>}
          </span>
        </summary>

        <FilterNav
          selectedFlags={selectedFlags}
          selectedGenre={selectedGenre}
          uniqueGenres={uniqueGenres}
          genreCount={genreCount}
          onSelectGenre={setSelectedGenre}
          onSelectFlag={(flag) =>
            setSelectedFlags((prev) => ({
              ...prev,
              [flag]: !prev[flag],
            }))
          }
        />
      </details>

      <aside tw="hidden md:block flex-shrink-0 [width:15rem] pr-8">
        <FilterNav
          selectedFlags={selectedFlags}
          selectedGenre={selectedGenre}
          uniqueGenres={uniqueGenres}
          genreCount={genreCount}
          onSelectGenre={setSelectedGenre}
          onSelectFlag={(flag) =>
            setSelectedFlags((prev) => ({
              ...prev,
              [flag]: !prev[flag],
            }))
          }
        />
      </aside>
      <main tw="flex-grow min-w-0 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-x-4 lg:gap-x-8 gap-y-10 lg:gap-y-16">
        {filteredList && filteredList.map((item) => <GameItem entry={item} key={item.playlist} />)}
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
