import React from "react";
import { useDebouncedCallback } from "use-debounce";
import "twin.macro";

const SearchInput = ({ onSearch, ...props }) => {
  const searchRef = React.useRef();

  const [value, setValue] = React.useState("");

  const handleSearchChange = useDebouncedCallback(onSearch, 150);

  return (
    <div tw="relative w-full md:[width:40rem]">
      <input
        ref={searchRef}
        tw="w-full outline-none px-4 py-3 bg-white bg-opacity-10 rounded-md pr-7 border-black focus:border-red-500 border-2 transition md:focus:shadow-[0px_0px_60px_-5px_rgba(239,68,68,0.25)]"
        placeholder="Search..."
        onChange={(e) => {
          setValue(e.target.value);
          handleSearchChange(e.target.value);
        }}
        value={value}
        {...props}
      />
      {value && (
        <button
          tw="absolute right-2 top-2 text-2xl text-gray-400 px-2 outline-none"
          type="button"
          onClick={() => {
            setValue("");
            onSearch("");
          }}>
          ×
        </button>
      )}
    </div>
  );
};

export default SearchInput;
