export const raw = `
GAME                                             GENRE                                                              F  J  S  MP  EA    IGDB      STATUS        PLAYLIST
Forspoken                                        Role-Playing, Action-Adventure, Fantasy                                                                       PLj_Goi54wf0eDR1LeM0DFcbumE4s58W1S
Inkulinati                                       Strategy, Medieval                                                                                            PLj_Goi54wf0dYwYp8V6KhG0PCDU2n1dLp
Vampire Survivors                                Action, Fantasy                                                                                               PLj_Goi54wf0eJfmEAU7HHieNM22axyQ5c
Children of Silentown                            Adventure, Mystery                                                                                            gYrei-MbBp0
Marvel's Midnight Suns (Light Hunter)            RPG, Strategy, Tactical, Action                                          X                                    PLj_Goi54wf0czkoi6cR21pUoZhTbEF_i1
Marvel's Midnight Suns (Dark Hunter)             RPG, Strategy, Tactical, Action                                                                               PLj_Goi54wf0dR2crl9jVtTyHcKbEsvj84
The Devil in Me                                  Adventure, Horror, Survival, Thriller                                 X                                       PLj_Goi54wf0fEQZlDmPKPr_a9XOg-drke
God of War Ragnarök                              Adventure, Hack and slash, Action, Fantasy, Historical                                                        PLj_Goi54wf0d_taxFvW7CkwKcT3ZI29Hk
A Plague Tale: Requiem                           Adventure, RPG, Action, Fantasy, Historical, Stealth                                                          PLj_Goi54wf0eoZ0GId69EgNv9Rd3ceNKT
Scorn                                            Adventure, Indie, Action, Horror                                                                              PLj_Goi54wf0fWsHeyR4XFFXG6m4Df9XLZ
Stardeus                                         Indie, RTS, RPG, Simulator, Strategy                                                                          PLj_Goi54wf0cqxurHt3lgnExOi4UVx99s
Dome Keeper                                      Action, Sci-fi                                                                                                PLj_Goi54wf0dqhcih7ednz02Z4aDF4AaB
The Secret of Monkey Island: Special Edition     Adventure, Point-and-click, Puzzle                                 X     X            65                      PLj_Goi54wf0dD3mhDTc7_A17XZ8Nx4N41
Metal: Hellsinger                                Adventure, Shooter, FPS                                                                                       PLj_Goi54wf0fHt_Qo38kJm_rhISmS4bfx
Circus Electrique                                Indie, RPG, Strategy, Tactical, TBS, Roguelike                                                                sPnrlFS7ewI
The Last of Us: Part 1                           Adventure, Shooter, Horror, Stealth                                X  X  X                                    PLj_Goi54wf0eoIjTDLnOleIz-RoUMhCV_
Hard West 2                                      RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0es0X9iQ2rZ1hpOwjeml0ew
Cult of the Lamb                                 Adventure, Indie, Simulator, Strategy                                                                         PLj_Goi54wf0eacJqpsSU87nypivswF5G4
Into The Breach Advanced Edition                 Deckbuilding, Indie, RPG, Turn-based                                                                          PLj_Goi54wf0fIEmry6pL_ME-h_AUIXHIK
Stray                                            Adventure, Indie                                                                                              PLj_Goi54wf0eWCnzeWEGKZDK9tgJZKuAr
Marvel's Midnight Suns (deep dive)               RPG, Strategy, Tactical                                                         X                             PLj_Goi54wf0eYCR_ngOkG6_mKmf4G9fc2
The Quarry                                       Action, Horror, Survival                                              X                                       PLj_Goi54wf0ebMDBm8MQ774_UnIsC-bPX
King Arthur: Knight's Tale                       RPG, TBS                                                                                                      PLj_Goi54wf0ebFqA-iKI5J-lAApEWmbaL
Warhammer 40,000: Chaos Gate - Daemonhunters     RPG, Strategy, Tactical                                                                                       PLj_Goi54wf0cXFQvanRhsSopYN4W-9Q6-
Rogue Legacy 2                                   Adventure, Indie, Platform, RPG                                                                               PLj_Goi54wf0ewjPjAXYZ7F-r8YcTw3jn5
Weird West                                       Adventure, Indie, RPG                                                                                         PLj_Goi54wf0fZ4uch2gjhN830zzz8JQ-z
Elden Ring                                       RPG, Soulslike                                                                                                PLj_Goi54wf0f4lRSDviEgpatNX9cIb-_0
Horizon: Forbidden West                          Adventure, RPG                                                                                                PLj_Goi54wf0e6c1JeHI5ZD-zIe4oeVZ9s
Dying Light 2                                    Action, Adventure, Survival                                        X     X                                    PLj_Goi54wf0eW4KcwPfnq9KYP37vLFE0V
Hidden Deep                                      Action, Horror, Survival, Platform                                                                            PLj_Goi54wf0dDJxSlPIsvX7JW-rIVGmlB
Dying Light                                      Action, Adventure, Survival                                        X     X                                    PLj_Goi54wf0cm5vYS-yJ_39pwFsc8Q55M
XCOM 2: WOTC 2022 (330+ mods)                    Strategy, Tactical, TBS                                                                                       PLj_Goi54wf0f7CMqZcyGBYiX1OBRz6JNS
Inscryption                                      Strategy, TBS, Deckbuilding                                                                                   PLj_Goi54wf0de3OyvTYMxqXCMs9E5p5-M
Heavenly Bodies                                  Adventure                                                                                                     PLj_Goi54wf0dW2ynQZcUSE6h4or5_Liw1
Halo Infinite                                    Action, Shooter                                                                                               PLj_Goi54wf0fHEGU6tt2l7fPVwmVALVlG
Fights In Tight Spaces                           Strategy, TBS, Deckbuilding                                                                                   PLj_Goi54wf0eieLUwU3sG1SuE2KwXT-Dk
House of Ashes                                   Action, Horror, Survival                                              X                                       PLj_Goi54wf0eRcHd51kd4SJMz79Q_4GIU
Marvel's Guardians of the Galaxy                 RPG, Action, Adventure, Linear                                                                                PLj_Goi54wf0dYYM1HvAhEdErpyOT5Umib
Darkest Dungeon 2                                RPG, Roguelike                                                                  X                             PLj_Goi54wf0fXTxphJAS6WINgcQUwPkO_
Back 4 Blood                                     Action, Shooter                                                             X                   Unfinished    PLj_Goi54wf0fzdr7p4hwTD3mh7z6pJfCQ
Alan Wake Remastered                             Action, Adventure, Survival                                                                     Unfinished    PLj_Goi54wf0eqxXQHPSGl_R0y3y7R13wO
Deathloop                                        Action, Shooter                                                                                               PLj_Goi54wf0dKVLYpHBT6k1SDsaFt4QWo
XCOM 2: WOTC 2021 (200+ mods)                    Strategy, Tactical, TBS                                                                                       PLj_Goi54wf0fU8p_HHSrF91yOnUVCwHVI
Chernobylite                                     Action, Adventure, Survival                                                                                   PLj_Goi54wf0cxjd4Lw4OtpESagVIZ2-aC
Death's Door                                     Action, Adventure                                                                                             PLj_Goi54wf0c6MZydXYN9l1gAA8zrdEoX
RimWorld: Ideology                               Strategy, RTS                                                                                                 PLj_Goi54wf0d7IHe9CWjS_u9rjkFHrkrm
Dream Engines                                    Action                                                                                                        PLj_Goi54wf0f_HdCPcuFEHhpcFZAoDq0u
Red Solstice 2                                   Action, Shooter                                                                                               PLj_Goi54wf0fq-zby0aurvFscRSTgLMtX
Death Trash                                      Action, RPG                                                                                                   PLj_Goi54wf0dngZ43U_TR6Bokg9UnRK_6
Tainted Grail                                    RPG, Strategy, Deckbuilding                                                                                   PLj_Goi54wf0f5nbe6AX4fLdJw5zE9SfhK
Biomutant                                        Action, RPG                                                                                                   PLj_Goi54wf0ftpyjkqY-rZCE0hvxT5RlC
Subnautica: Below Zero                           Adventure, Action, Sandbox                                                                                    PLj_Goi54wf0fwxP7uqDPFiCeG0Y4jhpUf
Mass Effect: Legendary                           Action, RPG                                                                                                   PLj_Goi54wf0dv7lpokxmox5HlD2Uzsp2O
Resident Evil Village                            Action, Adventure, Survival, Horror                                                                           PLj_Goi54wf0d2G3YH3l63SlT-IYuMo1ko
Warpips                                          Strategy, RTS                                                                                                 PLj_Goi54wf0fNmz7lRe6bRxZbZLiXWcXI
Evil Genius 2 (Ivan Campaign)                    Strategy, RTS                                                                                                 PLj_Goi54wf0f1e5kESFN0WGZN1aG-CIQp
It Takes Two                                     Action, Adventure, Linear                                                   X                                 PLj_Goi54wf0dbpRQSz_gOrDdPt_QfNjWz
Loop Hero                                        RPG                                                                                                           PLj_Goi54wf0duPMOiJyDX4CVALxtv45Ob
Obra Dinn                                        Puzzle                                                                                                        PLj_Goi54wf0cCIpph35dFEatMIigaIQNk
Little Nightmares 2                              Platform, Horror, Mystery                                                                                     PLj_Goi54wf0eW29MWK7TNUIhF8BeLqbkf
The Medium                                       Action, Survival, Horror, Mystery                                                                             PLj_Goi54wf0dho7jqDQkwG2wJ-n3RLE5L
Papers, Please                                   Adventure, Puzzle                                                                                             PLj_Goi54wf0ds8-XF5_QXZ7liu2B8mvbt
Cyberpunk 2077                                   RPG, Action, Exploration                                                                                      PLj_Goi54wf0cmStXr2tLz2lAZQD9qLWlG
Empire of Sin                                    Adventure, RPG, Tactical                                                                                      PLj_Goi54wf0cZiSCqhKbCEHqd0pKVBZWt
Spider-Man: Miles Morales                        Adventure, Action                                                                                             PLj_Goi54wf0eqm0a_Y7ZsIzRsbwQyPhVL
Demon's Souls Remake                             RPG, Action, Soulslike                                                                                        PLj_Goi54wf0eWPfmwkyzj3NJROdBsgbTe
Bugsnax                                          Adventure, Puzzle                                                                                             PLj_Goi54wf0dVGMUEkgAxbXYsqvTHr7kp
Godfall                                          Action, RPG, Soulslike                                                                                        PLj_Goi54wf0fsOYo7IDm6EJMmPNn_h3BY
Hades                                            Adventure, Roguelike                                                                                          PLj_Goi54wf0c9k4uw0NSaQDniGBD8-O3u
Little Hope                                      Adventure, Horror, Mystery, Survival                               X  X                                       PLj_Goi54wf0codd5ZsP_S51nI5xEO8NE2
Amnesia: Rebirth                                 Adventure, Puzzle, Horror, Survival                                X  X                                       PLj_Goi54wf0fXcHopLB3ieThMLQ3uCCV1
Song of Horror                                   Adventure, Horror, Survival                                           X                                       PLj_Goi54wf0djnsKH8AkiZGfs7rj9Me-X
Baldur's Gate III                                Adventure, RPG, TBS, Tactical                                               X   X                             PLj_Goi54wf0fREtdfmZwlCdq8kiIDR2fM
Mafia Remake                                     Adventure, Shooter                                                                                            PLj_Goi54wf0fI6V6X6QdhQLa7w7kTDrJG
Star Renegades                                   Adventure, RPG, Strategy, TBS                                                                                 PLj_Goi54wf0cnwjckb66m9XN01S49E9el
Wasteland 3                                      Adventure, RPG, Strategy, TBS                                                                                 PLj_Goi54wf0cBK9RWa7lUGCkckQ4-6mbp
Mortal Shell                                     Action, Soulslike                                                                                             PLj_Goi54wf0dhJTETFuNBK_lffV6Uop4P
Othercide                                        Adventure, RPG, Strategy, TBS                                                                                 PLj_Goi54wf0fGpE4dqvE2LSCzKgHKGmQ5
Ghost of Tsushima                                Adventure, RPG, Exploration                                                                                   PLj_Goi54wf0cA5GFtf6KvHZLlZ_YdMFIL
Disco Elysium                                    Adventure, RPG                                                                                                PLj_Goi54wf0epAyiCpmd_nX9mX2xrYcWM
The Last of Us 2                                 Adventure, Shooter                                                                                            PLj_Goi54wf0di8ySa2fynkco8G7aEBRsI
Griftlands                                       Indie, RTS, RPG                                                                                               PLj_Goi54wf0fd5nAM3YJ5Plg7M7BgUbly
The Last of Us (2020)                            Adventure, Shooter                                                                                            PLj_Goi54wf0dJ0Ae0Ei7yP3bqeHT-qv0U
Monster Train                                    Deckbuilding, Indie, RPG, TBS                                                                                 PLj_Goi54wf0cmy-b8jR8Fh-uY5zrzHBjy
Gears Tactics                                    Strategy, Tactical                                                                                            PLj_Goi54wf0c4Hjn-c2D4XTNiLV29mys-
XCOM 2 (Chimera Squad)                           Strategy, Tactical, TBS                                                                                       PLj_Goi54wf0dXwfSSZ4a2RZsAM8uLaLOe
Iratus: Lord of the Dead                         Indie, RPG, Strategy, TBS                                                                                     PLj_Goi54wf0cFU_EDCFPjqTyC3fvG-6hA
Final Fantasy VII Remake                         Adventure, RPG                                                                                                PLj_Goi54wf0e1JfnFcnI23xXeEEJul6Gz
Resident Evil 3                                  Adventure, Shooter                                                                                            PLj_Goi54wf0dIvVg_CN9x5HPJ18fbxSiO
Iron Danger                                      Adventure, Indie, RPG, Tactical                                                                               PLj_Goi54wf0ewQJCQ_AtVVyUcquYPggdd
Half-Life: Alyx                                  Adventure, Puzzle, Shooter                                                                                    PLj_Goi54wf0dwwgkI6nAMITwckvupcHsI
Doom: Eternal                                    Shooter                                                                                                       PLj_Goi54wf0egKvWBjWSaYDPM-G1tB55Y
Ori and the Will of the Wisps                    Adventure, Indie, Platform, Puzzle                                                                            PLj_Goi54wf0fAQT4pS_If7MgM45EslFkL
Ori and the Blind Forest                         Adventure, Indie, Platform, Puzzle                                                                            PLj_Goi54wf0eDdttevBjyaPDS1jpyR9PK
Darkest Dungeon (modded)                         Adventure, Indie, RPG, Strategy, TBS                                                                          PLj_Goi54wf0cZadwn6YGsHGpbC7bITOOi
Doom                                             Shooter                                                                                                       PLj_Goi54wf0eMcsBnnc6IZ_wAPeHC0WDR
Not For Broadcast                                Adventure, Indie, Simulator                                                                                   PLj_Goi54wf0eDYIxeE2DiB5N6ZJjYEUSP
Journey to the Savage Planet                     Adventure                                                                                                     PLj_Goi54wf0fLNq59TuyqhwCV7njorb9d
Star Wars Jedi: Fallen Order                     Action, Fantasy, Science fiction                                                                              PLj_Goi54wf0cL7y2jpRSfxR0jBdPIIdyP
Phoenix Point                                    Strategy, Tactical                                                                                            PLj_Goi54wf0e_Zv9pDrD1AzOIZQSRgT-d
Death Stranding                                  Adventure, Shooter                                                                                            PLj_Goi54wf0dsr0OSV2FzAIWzMDOJR382
The Outer Worlds                                 Adventure, Indie, Puzzle                                                                                      PLj_Goi54wf0fwXTa1nT7jigvBg1MXGA63
Surviving The Aftermath                          Simulator, Strategy                                                             X                             PLj_Goi54wf0e2KPZCGfaIV53-GWv2lWDB
XCOM 2 (season 5)                                RPG, Strategy, Tactical, TBS                                                                                  PLj_Goi54wf0cO-1VtHYJhp476bH7i6nJw
Greedfall                                        Adventure, RPG, Strategy                                                                                      PLj_Goi54wf0dF8Kzqjuq4ecQj2EnsiNob
Blasphemous                                      Action, Fantasy                                                                                               PLj_Goi54wf0dgko_c-LT3jcFOeJvc9sy9
Blair Witch                                      Action, Horror, Survival, Thriller                                    X                                       PLj_Goi54wf0eds7nRu8wYYHDGXcf3osEM
Man of Medan                                     Horror, Mystery, Survival                                             X                                       PLj_Goi54wf0dwKAP8v2EQXHxV-xjyuMy_
Control                                          Adventure, Shooter                                                                                            PLj_Goi54wf0cLEQqdyWZVHkHA9vPJkutu
Age of Wonders (campaign)                        RPG, Strategy, TBS                                                                                            PLj_Goi54wf0fq2sBtFZ6Jx9AgJdl-_xEd
The Surge 2                                      Action, Science fiction                                                         X                             PLj_Goi54wf0dDikPuCog7mX7G7RnxpKr_
Gloomhaven                                       Adventure, RPG, Strategy, Tactical, TBS                                                                       PLj_Goi54wf0eMIDc9EHBO4QA5zDutbRqe
The Sinking City                                 Adventure, Puzzle, RPG, Shooter                                                                 Unfinished    PLj_Goi54wf0cubsDAymYsJR2yWh-O1lb0
They Are Billions (campaign)                     Indie, RTS, RPG, Strategy                                                                                     PLj_Goi54wf0eNoQKviakxKNULUuACLLUr
Void Bastards                                    Arcade, Shooter, Strategy                                                                                     PLj_Goi54wf0e50CocCMEgnsqoPzCkTYC_
Outer Wilds                                      Adventure, Indie, Puzzle                                                                                      PLj_Goi54wf0dWwjeYSPPc1Grg3FgmuCMq
Draugen                                          Fantasy, Historical, Horror, Mystery                                                                          PLj_Goi54wf0dvOOfFgwDfd4gQQJj2wqtf
Layers of Fear 2                                 Adventure, Indie, Simulator                                           X                                       PLj_Goi54wf0fpS-MT0OdQXx4n5Ge1s2YY
Observation                                      Adventure, Indie, Puzzle, Simulator                                                                           PLj_Goi54wf0fqSNGpM7YnHIaDx-nKsdvj
Plague Tale: Innocence                           Action, Fantasy, Historical, Stealth                                                                          PLj_Goi54wf0dgQr-c5WXpYP0iqi8hE_MF
Close To The Sun                                 Adventure, Indie                                                                                              PLj_Goi54wf0dp-Djdg5Fy5kPzztF6QE7y
Days Gone                                        Adventure, Shooter                                                                                            PLj_Goi54wf0cpP9TRI_ZkgjojNsUY9ldD
Weedcraft Inc                                    Indie, Simulator, Strategy                                                                                    PLj_Goi54wf0eaWFfujzv_xTFLp7NZWmpT
Sekiro: Shadows Die Twice                        Action, RPG, Fantasy, Stealth, Soulslike                                                                      PLj_Goi54wf0doBA4oOfHzwO4Q9d2uW5eB
Satisfactory                                     Open world, Sandbox, Science fiction                                            X                             PLj_Goi54wf0fTXIaCQCYiGQKBzNGKx4zr
The Occupation                                   Mystery, Stealth, Thriller                                                                                    PLj_Goi54wf0fft6wC21C665Vfn-JZOOsG
Metro Exodus                                     Action, Horror, Survival                                                                                      PLj_Goi54wf0crhejwwkQlGucYtD0LbNBL
Civilization VI (Canada)                         4X, Fantasy, Historical, Turn-based                                                                           PLj_Goi54wf0e7lvxC_GobVK6ssdegB0_n
Resident Evil 2                                  Action, Horror, Survival                                                                                      PLj_Goi54wf0dZCO2RYymCPvSLdRfQG8qX
Resident Evil 1                                  Action, Horror, Survival                                                                                      PLj_Goi54wf0ensLilajfl-_1wrhZ_0Qbd
Celeste                                          Action, Fantasy, Platform                                                                                     PLj_Goi54wf0f5_CV3w6bEZ7UuUhOwNUjl
XCOM 2 (2019)                                    Science fiction, Tactical, Turn-based                                                                         PLj_Goi54wf0dRs0dsBfzwzTyjKDMdyMIc
Kingdom: Two Crowns                              Business, Fantasy, Mystery                                                                                    PLj_Goi54wf0e6Mr88P40xDVo0Z_cl-Nl8
Mutant Zero: Road to Eden                        RPG, Turn-based, Tactical                                                                                     PLj_Goi54wf0c1BFLRYIMpzkkqoCKk4mDu
Mutant Zero: Road to Eden                        RPG, Turn-based, Tactical                                                       X                             PLj_Goi54wf0eLjnM8y62R-Bat9ty3ZQnL
Red Dead Redemption 2                            RPG, Shooter, Open world, Sandbox                                                                             PLj_Goi54wf0cWczFRuKI0VrclV37EzmOW
Thronebreaker: The Witcher Tales                 Deckbuilding, Turn-based                                                                                      PLj_Goi54wf0dA0ylZouW8Q6C7-VX1SMQi
Visage                                           Action, Horror                                                        X         X                             PLj_Goi54wf0cZCUnRYWsia8EgYkYaNydV
XCOM 2: WOTK - Tactical Legacy Pack              Strategy, Turn-based                                                                                          PLj_Goi54wf0cCVVw-apYxaVJrCrwxxcL8
Magic The Gathering                              Deckbuilding                                                                                                  PLj_Goi54wf0fv6qjccF1mtnRGznZCofj7
60 Parsecs!                                      Point-and-click, Indie, Adventure                                                                             PLj_Goi54wf0ftFukC4bKOzYL5I5A8XSj0
Life Is Strange 2                                Adventure, RPG                                                                                                PLj_Goi54wf0fzNZKerM3SgBqQfXzEq7lx
Transference                                     Indie, Puzzle, Horror, Thriller                                                                               PLj_Goi54wf0dSpoY9tPGY1UMuVRJu4GKs
Shadow of the Tomb Raider                        Adventure, Shooter, Historical, Mystery                                                                       PLj_Goi54wf0eOlf_GP8nGNxI94c3qbmyh
Spider-Man                                       Adventure, Platform                                                                                           PLj_Goi54wf0crBSJE42clYAkftwakwWhf
The Inner Friend                                 Adventure, Indie, Puzzle, Horror                                                                              PLj_Goi54wf0favEMpT9uTrMYLkwc5JRYL
Red Dead Redemption                              RPG, Shooter, Open world, Sandbox                                                                             PLj_Goi54wf0eiNKe4RvhUi5TcE0nqz0WE
Phantom Doctrine                                 Adventure, RPG, Turn-based                                                                                    PLj_Goi54wf0eRTa5aF_574HoFOUUOEAm5
We Happy Few (2018)                              Adventure, Indie, Shooter                                                                                     PLj_Goi54wf0eaVIll59R1nQrgHjHRqgIp
This Is The Police 2                             Adventure, Indie, Turn-based                                                                                  PLj_Goi54wf0fXrAoULR_4SbAxh6y3L3n1
Slay The Spire                                   Deckbuilding, Indie, Turn-based                                                                               PLj_Goi54wf0cdye6Fjnda9cd-IsAnmEIr
The Awesome Adventures of Captain Spirit         Adventure                                                                                                     PLj_Goi54wf0dn3uxk0OGBz05XHle4nx7l
Vampyr                                           Adventure, RPG                                                                                                PLj_Goi54wf0f7WzTLHnZWaSwiDE-p2RO6
Detroit: Become Human                            Adventure, Puzzle                                                                                             PLj_Goi54wf0etCfYr04U-a3j-cQGztiEY
God of War                                       Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0cgpOuy60CdTQLi4Q-BrIGS
BattleTech                                       Adventure, Turn-based                                                                                         PLj_Goi54wf0c2KePhyJHo7vvoH_OgNOgX
Frostpunk                                        Indie, Simulator, Strategy                                                                                    PLj_Goi54wf0ebQbOR5_3i3q9f2oHvlAlK
XCOM 2: WOTK modded (perma, MOCX)                RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0dv7Mzs9f9mBbfQtiuUqH5v
BattleTech                                       Adventure, Turn-based                                                           X                             PLj_Goi54wf0dwtYQHYOd17pCOAFqb6JV5
A Way Out                                        Adventure, Indie                                                            X                                 PLj_Goi54wf0eQ1WFc6W_GM1MFiXP8nXpY
The Council                                      Adventure, RPG                                                                                                PLj_Goi54wf0dNBY5-vdfAQlZSsWmTeQ44
Final Fantasy XV                                 Adventure, RPG                                                                                                PLj_Goi54wf0cWVeBG7QOu-2H_colyLoQs
Into the Breach                                  Deckbuilding, Indie, Puzzle, Turn-based                                                                       PLj_Goi54wf0e7T6lyGGzch1VPIiZdG_Gw
Orwell: Ignorance is Strength                    Adventure, Point-and-click, Indie, Puzzle                                                                     PLj_Goi54wf0fD_ucU753yH2aSa34CaR6o
Kingdom Come: Deliverance                        Adventure, RPG                                                                                                PLj_Goi54wf0eTmhLCjC3d6cgFVou-FJjg
Subnautica                                       Adventure, Indie, Open world, Survival                                                                        PLj_Goi54wf0fdrV3eNO4Ct5NSE1PUyb9G
Shadow of the Colossus                           Adventure, Platform, Puzzle, Open world                                                                       PLj_Goi54wf0eWfQG22k7u6R63ptTfjdfn
Darkwood                                         Adventure, Indie, RPG, Horror, Survival                                X                                      PLj_Goi54wf0ee51EOogz1_e9of5JiVxRD
Fade to Silence (early access)                   Adventure, RPG, Open world                                                                                    PLj_Goi54wf0drpvOme1wO7HKWVCc8OrlX
They Are Billions (Peaceful Lowlands)            Indie, RTS, RPG, Strategy                                                                                     PLj_Goi54wf0czvhozV72HNE2N6xwjblte
XCOM 2: WOTC modded                              RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0eKfIwCCXmRHxfVZT_aPg1C
They Are Billions (Colony Golf)                  Indie, RTS, RPG, Strategy                                                                                     PLj_Goi54wf0fYsYvZ4Oq99W8OyoZtypTK
They Are Billions (Bravo Colony)                 Indie, RTS, RPG, Strategy                                                                                     PLj_Goi54wf0d72nz7vqLD6WOr7OiIr5H5
They Are Billions (Alpha Colony)                 Indie, RTS, RPG, Strategy                                                                                     PLj_Goi54wf0e2fOFwrlv0yzZm9-wwcdVR
Horizon: The Frozen Wilds                        Adventure, RPG, Open world                                                                                    PLj_Goi54wf0dT49UkHK7xzbhcg3I-HADl
Wolfenstein II: The New Colossus                 Adventure, Shooter                                                                                            PLj_Goi54wf0e8MPeHDLf4vrgvNxtcL_3H
Wolfenstein: The New Order                       Adventure, Shooter                                                                                            PLj_Goi54wf0dpydCz6EmPVR6buIBXq3i3
The Evil Within 2                                Adventure, Shooter, Horror, Survival                                   X                                      PLj_Goi54wf0diAgHbytaAx-u-q5y7-04a
Divinity: Original Sin 2                         Adventure, Indie, RPG, Turn-based                                           X                                 PLj_Goi54wf0cmBbjU7T0ePfZpZ_Yzh6Rq
XCOM 2: War of the Chosen                        RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0f7l1LG6r7A8hXARu_Qdbni
Hellblade: Senua's Sacrifice                     Adventure, Soulslike, Indie                                                                                   PLj_Goi54wf0cO7xpquQ649rZYbabDWPnd
Observer                                         Adventure, Indie, Horror, Thriller                                                                            PLj_Goi54wf0fPzs-eb_LIM811lWnCVZxu
The Darkside Detective                           Adventure, Indie, Point-and-click, Puzzle                                                                     PLj_Goi54wf0dhCUENS9YEHVk2DtXKFhVd
What Remains of Edith Finch                      Adventure, Indie                                                                                              PLj_Goi54wf0cPbmgkhWnur2XuD5CFXSQo
Perception                                       Adventure, Indie, Horror                                                                                      PLj_Goi54wf0euPV9oZiOY9VXTgQiauoWW
The Surge                                        Adventure, RPG                                                                                                PLj_Goi54wf0cPJpXqbnpehpNo1hplX2JP
Prey                                             Adventure, Shooter                                                                                            PLj_Goi54wf0e2xMvbQ4Hd9T6RzGgoOUeK
Little Nightmares                                Adventure, Platform, Puzzle                                                                                   PLj_Goi54wf0c_cSau6aJNd7tuNwHgYH-5
Scanner Sombre                                   Adventure, Indie                                                                                              PLj_Goi54wf0eFcQH6jZRs1jrPS0Ho4eE1
Outlast 2                                        Adventure, Indie, Horror, Survival                                    X                                       PLj_Goi54wf0ffrBSJ7h3s1mXSG_6SQenA
Guardians of the Galaxy                          Adventure, RPG, Shooter                                                                                       PLj_Goi54wf0dsqZgjmchjOcO8mhV03gEX
Dark Souls 3: The Ringed City                    Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0cU1cIg8TQWYbNTh5fZvhVn
Mass Effect: Andromeda                           Adventure, RPG, Shooter                                                                                       PLj_Goi54wf0dESTgpSjnYK0v5RQ0KjVC5
Horizon: Zero Dawn                               Adventure, RPG, Open world                                                                                    PLj_Goi54wf0faUnvR9ZO8pHAW90v6G1j3
Nioh                                             Soulslike, RPG                                                                                                PLj_Goi54wf0czlavJIGZjcrLNekseQtRi
Resident Evil 7                                  Adventure, Shooter, Horror, Survival                                  X                                       PLj_Goi54wf0cEeYmWEVQdTUyGhwuF4eN8
XCOM 2: Long War 2                               RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0d9dwHMnMHpwiRvhN60WYEX
XCOM 2 (campaign with Marbozir)                  RPG, Strategy, Tactical, Turn-based                                         X                                 PLj_Goi54wf0exejWUmWRCWcvZSJv4dO11
The Last Guardian                                Adventure, Puzzle, Platform                                                                                   PLj_Goi54wf0f3Tud_ln3XWmIx9vml56KK
MAIZE                                            Adventure, Indie, Puzzle                                                                                      PLj_Goi54wf0f9c8JHbwI9K2TOHxGMQhkW
Dishonored 2                                     Adventure, RPG, Shooter, Stealth                                                                              PLj_Goi54wf0ef0olOuJrHVUHP5a0HXove
Tyranny                                          Adventure, RPG, Strategy, Turn-based                                                                          PLj_Goi54wf0c0u7PIcy9sxq7G7Nb9_7k_
Small Radios Big Televisions                     Adventure, Indie, Puzzle                                                                                      PLj_Goi54wf0fr48rhiANRDOMjTJ9ZEP5l
Orwell                                           Adventure, Indie, Simulator                                                                                   PLj_Goi54wf0fOyvqbdYm98vesiSadHyPC
Dark Souls 3: Ashes of Ariandel                  Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0ewaULIsCZbv_9qhM9dE0TG
Civilization VI (Scythia)                        4X, Simulator, Strategy, Turn-based                                                                           PLj_Goi54wf0fbNJNLbeMiBWaEjUly6p2B
Gwent                                            Adventure, Deckbuilding, RPG, Strategy                                                                        PLj_Goi54wf0cl7omA6rMNYzI5KQT-RSul
Divinity: Original Sin 2                         RPG, Turn-based                                                             X   X                             PLj_Goi54wf0ddiHYw9PX7P9dVw_8oAhZ0
Event[0]                                         Adventure, Indie, Puzzle                                                                                      PLj_Goi54wf0dK2Oct2Jg0oLBlViOi3KqZ
RimWorld Alpha 15                                Indie, RTS, Simulator, Strategy                                                                               PLj_Goi54wf0d_XCoWEEQyK8XZaaY_LsS2
The Turing Test                                  Adventure, Puzzle                                                                                             PLj_Goi54wf0cn2lNvoCb1Ys8IZyTeUOQF
Deus Ex: Mankind Divided                         Adventure, RPG, Shooter                                                                                       PLj_Goi54wf0fbtz9765nsY6SWAF9y1hxq
No Man's Sky                                     Adventure, RPG, Shooter, Simulator                                                                            PLj_Goi54wf0dWaNnq7N493TAHP5fn1itX
XCOM 2 (season 4)                                RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0frL4I5Qjksy44_-S6iBrlK
We Happy Few                                     Adventure, Indie, Shooter                                                                                     PLj_Goi54wf0dxVnbJc9P59hQ7YG2TE_TE
Inside                                           Adventure, Indie, RPG                                                                                         PLj_Goi54wf0c_ROU0U0AQoS6FMBqjGpfy
Mirror's Edge: Catalyst                          Adventure, Fighting                                                                                           PLj_Goi54wf0ecEihGMgq0OfcMVm3L1J3L
The Witcher 3: Blood and Wine                    Adventure, RPG, Open world                                                                                    PLj_Goi54wf0crQ6pN58DM0ymfmUxWzTcm
Uncharted 4                                      Adventure, Shooter                                                                                            PLj_Goi54wf0fxCRNZiinKcmsNyre0LHP3
XCOM 2 (modded legend)                           RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0fYB_HrLSN1jn0WTXRTYWBp
Dark Souls 3                                     Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0eWgKRdI9urGU3XHqhR7GS9
Quantum Break                                    Adventure, Shooter                                                                                            PLj_Goi54wf0dQgdLCxieBNa_Ala_MoCrt
Heavy Rain                                       Adventure, Mystery, Thriller                                                                                  PLj_Goi54wf0cpPv6RbQNrqypOe9VdsgCR
Salt and Sanctuary                               Adventure, Indie, Platform, RPG                                                                               PLj_Goi54wf0dmPGUF3zb7pNjCfTmz_hLt
The Walking Dead: Michonne                       Adventure, Point-and-click                                                                                    PLj_Goi54wf0eJpdXNzyHSFOOcdDWfE1J_
Layers of Fear                                   Adventure, Indie, Horror, Survival                                    X                                       PLj_Goi54wf0dlJ1cl4qKfLD_K3QnM7O1u
Firewatch                                        Adventure, Indie                                                                X                             PLj_Goi54wf0dxzeTye9BQq9nvVBvF7C6M
XCOM 2 (legend)                                  RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0f2XYwenlNTbLJH7P3LiiDS
Oxenfree                                         Adventure, Indie, RPG                                                                                         PLj_Goi54wf0eiwx2Z5jLFjyzw-Q3CRanq
XCOM 2 (skill trees)                             RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0diul-tKNrfbtzLD6x5JeEi
XCOM 2 (let's play)                              RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0dsmRAeUZntJ8FNc92hJn8v
XCOM 2 (preview)                                 RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0ektWUNaDQnPlRZ_BcOXzgG
Bloodborne: The Old Hunters                      Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0eu6jG9C0IwNn4nvVhMNYHZ
XCOM: Enemy Within (2015/2016)                   RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0d7r564Xz4OGZCw_vG4k_Wa
Hard West                                        Adventure, Indie, RPG, Turn-based                                                                             PLj_Goi54wf0c6il2tkuiaK986lnUQ7VWQ
Fallout 4                                        RPG, Shooter                                                                                                  PLj_Goi54wf0dHhjOgCdOkQJVg9ZrpaYCv
Divinity: Original Sin                           Adventure, Indie, RPG, Turn-based                                                                             PLj_Goi54wf0dj9jPIwdu54o6NHz3nPM3D
Rise of the Tomb Raider                          Adventure, Shooter                                                                                            PLj_Goi54wf0fI-9sic-dNbfNpRqWdNuKF
Kingdom                                          Adventure, Indie, Simulator, Strategy                                                                         PLj_Goi54wf0cczZuD9rjqkHNZZVWvW6Cf
Minecraft: Story Mode                            Adventure                                                                                                     PLj_Goi54wf0fwneRoJLeTgGxFboWpX_4R
The Witcher 3: Hearts of Stone                   Adventure, RPG, Open world                                                                                    PLj_Goi54wf0f8NV2vWwmWjztawUoqR64V
Keep Talking and Nobody Explodes                 Deckbuilding, Indie, Puzzle, Simulator                                                                        PLj_Goi54wf0eWcN7H7Vbw9r7EIa5R0n1I
Uncharted: Nathan Drake Collection               Adventure, Platform, Shooter                                                                                  PLj_Goi54wf0fzEeuyeDnorh75cFQUwNCn
The Beginner's Guide                             Adventure, Indie                                                                                              PLj_Goi54wf0fx-e0Jx-Viz7EFKC7efKfY
Stasis                                           Adventure, Indie, Point-and-click                                                                             PLj_Goi54wf0eMGa2HPbaXYccMcQtR-yXl
SOMA                                             Adventure, Indie                                                                                              PLj_Goi54wf0cJgvM-kHtxSK4AT9LmHg3M
Skyshine's Bedlam                                Adventure, Indie, Strategy, Turn-based                                                                        PLj_Goi54wf0ciGlzzAPUKWAJUiYQ6pN3z
Layers of Fear                                   Adventure, Indie, Horror, Survival                                    X          X                            PLj_Goi54wf0cDsAHV9kOb1wiuCPplq2p7
Metal Gear Solid V: The Phantom Pain             Adventure, Shooter, Tactical                                                                                  PLj_Goi54wf0dFRvsjP5PQfpR0JA7NeCv9
Until Dawn                                       Adventure, Survival, Horror                                           X                                       PLj_Goi54wf0fIPpJmqcXk3HAAbd20sXGD
Everybody's Gone to the Rapture                  Adventure, Indie                                                                                              PLj_Goi54wf0dY7xO6i4Ay_-U4_nX2lnPS
XCOM: Enemy Within - ALLSTARS                    RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0ebfugAieSgeZ2KUqZySy0t
Fallout 3                                        RPG, Shooter                                                                                                  PLj_Goi54wf0cTamOKPNiV5UoRec5QgXsq
Her Story                                        Adventure, Indie, Point-and-click                                                                             PLj_Goi54wf0dXCH3BH29904HeaKjgOKwD
Portal Stories: Mel                              Adventure, Puzzle, Strategy                                                                                   PLj_Goi54wf0dtkXvI8nOCZi8uSI6IJt0u
This War of Mine                                 Adventure, Indie, Simulator, Strategy                                                                         PLj_Goi54wf0fKabWuadzFn_61y6_i4llL
We Happy Few                                     Adventure, Indie, Shooter                                                        X                            PLj_Goi54wf0c-8OdsKL6PwARFI-4ZuO1a
Magicka 2                                        Adventure, RPG                                                              X                                 PLj_Goi54wf0dA9oW_TiKNlUuAVon5kwR2
The Witcher 3: Wild Hunt                         Adventure, RPG, Open world                                                                                    PLj_Goi54wf0fQPWB_hjgmd3i1yIXeLXHI
Bloodborne                                       Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0cVzyMPApvLX5Q60N2fy5Ma
Pillars of Eternity                              Adventure, Indie, RPG, Turn-based                                                                             PLj_Goi54wf0fmDPcLTm46T17183kJhq-6
The Witcher 2 (alternate path)                   Adventure, RPG, Open world                                                                                    PLj_Goi54wf0e2b57xaG9lnZ0Rm7eKHtaR
The Order 1886                                   Adventure, Shooter                                                                                            PLj_Goi54wf0dLbHFYDVvshB0_M07IaMpu
To the Moon                                      Adventure, Indie, Point-and-click                                                                             PLj_Goi54wf0etlUm-p4bHhJMn-Kc3rtQI
Life Is Strange                                  Adventure, RPG                                                                                                PLj_Goi54wf0eI4bvClI7Fl-WtN975nmwW
Game of Thrones                                  Adventure, RPG                                                                                                PLj_Goi54wf0c29gm-MFIdS3CXkxSJoKxc
Dragon Age: Inquisition                          Adventure, RPG, Tactical                                                                                      PLj_Goi54wf0esy4JFDk2ix5Y5-es-S6R0
Alien Isolation                                  Adventure, Horror, Survival                                                                                   PLj_Goi54wf0c5TCrHuNASCW4OzI-pTs87
Lords of the Fallen                              RPG                                                                                                           PLj_Goi54wf0fHe_Bg93W4Ak-xU0ev_7rE
XCOM: Enemy Within - Long War Beta 14            Strategy, Turn-based                                                                                          PLj_Goi54wf0czodTpBvg7r-c4AWinX8X9
Borderlands: The Pre-sequel                      RPG, Shooter                                                                X                                 PLj_Goi54wf0d58h1S4FB9SUaAcxV7W9iM
Middle Earth: Shadow of Mordor                   Adventure, RPG                                                                                                PLj_Goi54wf0cSLbHO5NFn8nOVioRoc1eK
The Vanishing of Ethan Carter                    Adventure, Indie                                                                                              PLj_Goi54wf0fwhP4doA2qNI1s2_i1tCX1
Wasteland 2                                      Adventure, Indie, RPG, Turn-based                                                                             PLj_Goi54wf0dZMfcqsf4FQ84foBCBqcN8
Destiny                                          RPG, Shooter                                                                                                  PLj_Goi54wf0fsDSo4lCn2qaAeFnWOtekb
XCOM: Enemy Within - Long War                    RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0cRL45CfV-jGSh5_sged96F
Dota 2                                           MOBA, Strategy                                                                                                PLj_Goi54wf0ePimfB4uFY3Cz3lo--32TK
Mind: Path to Thalamus                           Adventure, Indie, Puzzle                                                                                      PLj_Goi54wf0f4i1fCM3YHth_vqp-pgLPZ
Mass Effect 3                                    Adventure, RPG, Shooter                                                     X                                 PLj_Goi54wf0dqdsQHE4bqjByyRga42WbD
The Fall                                         Adventure, Indie, Platform, RPG, Shooter                                                                      PLj_Goi54wf0cD4HHv0gakAcFz_1e3IeL8
Sniper Elite 3                                   Adventure, Shooter, Simulator, Tactical                                                                       PLj_Goi54wf0f2iDr6v49_mFaRo-ndodoP
Valiant Hearts: The Great War                    Adventure, Indie, Platform, Puzzle                                                                            PLj_Goi54wf0cjbwGNxOdMl_p9STDFlB3n
Mass Effect 3                                    Adventure, RPG, Shooter                                                                                       PLj_Goi54wf0eWGtEu_pDz_0qHxlMxxQTp
Outlast: Whistleblower                           Adventure, Horror, Survival                                           X                                       PLj_Goi54wf0c0oLEOcOXrxL_uvju-jScr
Watch Dogs                                       Adventure, Shooter                                                                                            PLj_Goi54wf0e94M4pcGeYmT9pbGj7whL5
Dark Souls 2 (PC)                                Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0dpTXybhw3pAvGi2EIveO2a
Vampires: The Masquerade                         RPG                                                                                                           PLj_Goi54wf0f7XsuAi8xs85sfK9g-TtDg
Team Fortress 2                                  Shooter                                                                  X  X                                 PLj_Goi54wf0dVexCXv9aSo19uBJqjVOox
Dark Souls - New Build                           Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0clZ22h6nL0__ODv6ZhUNc4
XCOM: Enemy Within - Second Wave                 RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0cwE3cOxqk0Pw0KmFfXFNbY
XCOM: Enemy Within - Army of Four                RPG, Strategy, Tactical, Turn-based                                                                           PLj_Goi54wf0fLjqrZCOjUPfI4gxcXCLvA
Bioshock Infinite: Burial At Sea Episode 2       Adventure, Shooter                                                                                            PLj_Goi54wf0cEKcLex2zFZXsNv9Rvp2br
Demon's Souls                                    Adventure, RPG, Soulslike                                                                                     PLj_Goi54wf0cEgdE3FhwDJ7Bw-0JALSR8
Dark Souls 2 (PS3)                               Adventure, RPG, Soulslike                                                                       Unfinished    PLj_Goi54wf0f5nRRp9TCqQmoqC5DyciQ_
Thief                                            Adventure, Shooter, Stealth                                                                                   PLj_Goi54wf0dcFDdqmbv7w-jSqKhIZal8
Mass Effect 2                                    Action, RPG                                                                                                   PLj_Goi54wf0dXp6rEgrj15WsA2S_ZrQVl
Dark Souls                                       Action, RPG, Soulslike                                                                                        PLj_Goi54wf0ctUYTfjBp5-K5n0_3V2cCr
Mass Effect                                      Action, RPG                                                                                                   PLj_Goi54wf0d63f2rghH-lRhydRCtQNiC
Contrast                                         Action, Puzzle                                                                                                PLj_Goi54wf0eK-AtzqaA5dV1p4FFNdbcp
Bioshock Infinite: Burial At Sea                 Action, Shooter, First-Person                                                                   Unfinished    PLj_Goi54wf0cETON_HuwQFG7CKG02A8Y7
XCOM: Enemy Within                               Strategy, Tactical                                                                                            PLj_Goi54wf0dstGblwXncow09AM8_oJG5
Alan Wake                                        Action, Horror, Survival                                                                                      PLj_Goi54wf0ed5y6N17M2DxCo9PCFmoxy
Brothers: A Tale of two Sons                     Action, Adventure, Fantasy                                                                                    PLj_Goi54wf0eYeVFZx_FeZ4XG0d46-Scs
The Wolf Among Us                                Action, Adventure, Fantasy                                                                                    PLj_Goi54wf0eEzW5wR_xLxGTih9CHy1SE
Beyond: Two Souls                                Adventure, Third-Person, Linear                                                                               PLj_Goi54wf0c-7op6d508mGiF50Yo-yak
Tomb Raider                                      Action, Adventure, Linear                                                                                     PLj_Goi54wf0cCn8F2kCv7LUgTpZHNiECO
Journey                                          Action, Platform                                                                                              PLj_Goi54wf0cKLvtDJsOYmB4SKMzhAMin
Gone Home                                        Adventure, First-Person                                                                                       PLj_Goi54wf0dRHheoYSSfRHREuFtUZiZu
Amnesia: A Machine for Pigs                      Adventure, Horror, Survival                                           X               1882                    PLj_Goi54wf0c3lveNZcnzI28HfETcJyjP
Outlast                                          Adventure, Horror, Survival                                           X               1910                    PLj_Goi54wf0cZmVdP1IgLMw1-3gCtPWZZ
Amnesia: The Dark Descent                        Adventure, Horror, Survival                                           X               111                     PLj_Goi54wf0eaqMOqYvV9FrDaaxatHOLl
Saints Row IV                                    Adventure, Shooter                                                                    1981                    PLj_Goi54wf0dLbe39PSEKdbLYP7GZdM7k
The Bureau: XCOM Declassified                    Shooter, Strategy, Tactical                                                           244                     PLj_Goi54wf0fSiEglBy3XIkJbYC8yWUKE
Dishonored: The Brigmore Witches                 Adventure, RPG, Stealth                                                               14569                   PLj_Goi54wf0ezuVlmf29zTXgXeyGTN2ft
Syberia                                          Adventure, Point-and-click, Puzzle                                                    890                     PLj_Goi54wf0fV1FO5knemGSxekeFZu36h
The Witcher 2: Assassins of Kings                Adventure, RPG                                                                        478                     PLj_Goi54wf0fCfQc-w8rgj2hUaHNOtopX
The Walking Dead                                 Adventure, Point-and-click                                                                                    PLj_Goi54wf0dsrlApZz75YQq_X6_2xL8n
Dungeons and Dragons                             Fantasy                                                                                                       PLj_Goi54wf0fcFRQnH_DtxlaXVx_h2rMR
The Last of Us                                   Adventure, Shooter                                                                    1009                    PLj_Goi54wf0e2YJ1uYtbkld-Ut6cbLs6c
Remember Me                                      Adventure, Platform                                                                   1827                    PLj_Goi54wf0eFysp-pZNJkqAz9iyFeNHg
Metro: Last Light                                Shooter                                                                               539                     PLj_Goi54wf0cF_HTCzlf16N_qy8OnGehn
The Witcher                                      Adventure, RPG                                                                        80                      PLj_Goi54wf0f2NXPeIvJqtLBSG9_nBTMM
Metro 2033                                       RPG, Shooter                                                                          495                     PLj_Goi54wf0cGHom_xgiwykUbmG9WpArt
Dishonored: The Knife of Dunwall                 Adventure, RPG, Stealth                                                               14568                   PLj_Goi54wf0duTSt9OSyd58uxwqPetX2A
Bastion                                          Adventure, RPG                                                                        1983                    PLj_Goi54wf0eZlXU75NrcHkFptF0vIIpX
Bioshock Infinite                                Adventure, Shooter                                                                    548                     PLj_Goi54wf0dtyzIPq3njm5C_mtRXWF2r
Crysis 3                                         Adventure, Shooter, Strategy                                                          1268                    PLj_Goi54wf0e83wtZtF8qVxtFEVaj7wua
The Bridge                                       Adventure, Platform, Puzzle, Strategy                                                 8440                    PLj_Goi54wf0eoj2Bw3Fm99R7teOhrZaCM
Dead Space 3                                     Adventure, Shooter                                                                    1216                    PLj_Goi54wf0cBx4CROqU01WI857GyPcuJ
The Cave                                         Adventure, Platform, Puzzle                                                           2175                    PLj_Goi54wf0cQQSyFS1nsEf5ZvlNp225_
Bioshock 2: Minerva's Den                        Adventure, RPG, Shooter                                                               14543                   PLj_Goi54wf0f-Fpg3gL-animf_Z5nPBuT
Deus Ex: Human Revolution                        RPG, Shooter                                                                          43                      PLj_Goi54wf0d0MWIuLHh9fwD63Z1tfyHv
Deus Ex                                          RPG, Shooter                                                                          41                      PLj_Goi54wf0f_8NRR7EPx8_tTUa-5gv4G
Bioshock 2                                       Adventure, RPG, Shooter                                                               21                      PLj_Goi54wf0fYsLXDo3XQVzocr-qFIqqh
Portal 2                                         Adventure, Platform, Puzzle, Shooter                                                  72                      PLj_Goi54wf0cad2iqh_uoofB079dvVb-Z
Assassins Creed 3                                Adventure, Strategy                                                                   1266      Unfinished    PLj_Goi54wf0fCut4qpP1kjQm9sW4W-Gh_
Hitman Absolution                                Action, Adventure                                                                     530                     PLj_Goi54wf0fh82s0QGPZz4050uY13clA
Half-Life 2: Episode Two                         Action, Shooter, First-Person                                                         247                     PLj_Goi54wf0fqAk-7qFTzn-NnLAroavV7
XCOM: Enemy Unknown                              Strategy, Tactical                                                                    1318                    PLj_Goi54wf0f_3ofYUNjA2prTJX5iO_5o
Portal                                           Action, Puzzle, First-Person                                                          71                      PLj_Goi54wf0cNy4bnG_3y5kQAC25FhFw0
Bioshock                                         Action, Shooter, First-Person                                                         152660    Completed     PLj_Goi54wf0frKcss4iVFfmcnIzrF8M_z
Half-Life 2: Episode One                         Action, Shooter, First-Person                                                         338       Completed     PLj_Goi54wf0cIaHbBuKIIK2qtldHGfaf9
Dishonored                                       Action, Adventure, Stealth                                                            533       Completed     PLj_Goi54wf0fYU1xR4JxtW5CSaHE3f5An
Half-Life 2                                      Action, Shooter, First-Person                                                         233       Completed     PLj_Goi54wf0d2xk_aLt-cFer_XTqI4qaN
Borderlands 2                                    Action, Shooter, First-Person                                                         1011                    PLj_Goi54wf0dFxFJCMybwWy7zVb1Qio6j
Black Mesa                                       Action, Shooter, First-Person                                                         6739                    PLj_Goi54wf0eI9TaYBxbrRrqDi3hCdPMr
Half-Life: Blue Shift                            Action, Shooter, First-Person                                                         2549      Completed     PLj_Goi54wf0fpfH_Qy1UNXIiXcRjg3JEe
Half-Life: Opposing Force                        Action, Shooter, First-Person                                                         232       Completed     PL04D7BF13494AA427
Tower Wars                                       Strategy, RTS                                                                         15310                   PL574F084279EBB750
Unmechanical                                     Action, Platform, Puzzle                                                              16368     Completed     PLD7E7B6AA66218E4B
Half-Life                                        Action, Shooter, First-Person                                                         231       Completed     PLAE471DFC1542BCE9
`
