import { tablatal } from "./tablatal";
import { indental } from "./indental";

import { raw } from "../db/playlists.tbtl";

/* ---------------------------------- */
/* Importing raw data
/* ---------------------------------- */

export const playlists = tablatal(raw);

/* ---------------------------------- */
/* Parse categories
/* ---------------------------------- */
